import { Injectable } from "@angular/core";
import { Http, RequestOptions, Headers } from "@angular/http";
import { Config } from "../config";
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from "rxjs/operators";

declare var toastr : any

@Injectable({
    providedIn: 'root'  // <- ADD THIS
})

export class LoginService{
    private infoFlagSource = new BehaviorSubject<boolean>(false);
    infoFlag$ = this.infoFlagSource.asObservable();
  
    private nameSource = new BehaviorSubject<string>('');
    name = this.nameSource.asObservable();
  
    tempValue: any;
    configUrl = new Config().apiUrl;
  
    constructor(private http: Http) {}
  
    changeName(name: string) {
      this.nameSource.next(name);
    }
  
    setInfoFlag(flag: boolean) {
      this.infoFlagSource.next(flag);
    }
    
    activateClient(data){
        return this.http.post(this.configUrl + '/client/activation' ,{client:data}).map(res=> res.json())
    }

    loginToApp(data){
        return this.http.post(this.configUrl + '/auth/login',data).map(res=> res.json())
    }

    verifyOtp(data){
        return this.http.post(this.configUrl + '/auth/otpvalidation',data).pipe(map(res=> res.json()))
    }

    verifyToken(token){
        return this.http.post(this.configUrl + '/auth/verifyToken', {token:token}).pipe(map(res=> res.json())) 
    }

    // getClientDetails(clientEmailID) {
    //     return this.http.post(this.configUrl + '/client/clientName', {accountEmail:clientEmailID}).map(res=> res.json())
    // }

    forgotPassword(emailID){
        return this.http.post(this.configUrl + '/client/forgetPassword',{email:emailID}).pipe(map(res => res.json()))
    }

    resetPassword(resetData){
        return this.http.post(this.configUrl + '/client/resetPassword',resetData).pipe(map(res => res.json()))
    }

    setValue(value){
        this.tempValue = value
        return
    }

    getValue(){
        return this.tempValue
    }
}