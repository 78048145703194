import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LoginService } from '../../services/loginService';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  islogin: any;

  constructor(
    public loginService: LoginService,
    private cdr: ChangeDetectorRef,
    private loaderService: NgxUiLoaderService
  ) {}

  ngOnInit() {
    this.loginService.infoFlag$.subscribe(flag => {
      // Handle the infoFlag changes here if necessary
      this.cdr.detectChanges(); // Ensure change detection is triggered if necessary
    });
  }

  logout(data) {
    setTimeout(() => {
      this.islogin = data;
      this.loginService.setInfoFlag(false); // Update infoFlag on logout
      this.cdr.detectChanges(); // Trigger change detection if needed
    });
  }

  // Example async operation to demonstrate loader usage
  someAsyncOperation() {
    this.loaderService.start(); // Start loader
    // Simulate an async operation
    setTimeout(() => {
      // Your logic here
      this.loaderService.stop(); // Stop loader
    }, 3000);
  }
}
