import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { LoginService } from "./loginService";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private loginService: LoginService) {}
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const idToken = localStorage.getItem("access_token");
    
    if (idToken) {
      const cloned = req.clone({
        headers: req.headers.set("Authorization", idToken)          
      });
      this.loginService.setInfoFlag(true); // Use setInfoFlag to update infoFlag
      return next.handle(cloned).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401 || error.status === 403) {
            this.loginService.setInfoFlag(false); // Use setInfoFlag to update infoFlag
            this.router.navigate(['/login']);
            return throwError(error); // Return the error as an observable
          } else {
            return throwError(error); // Return the error as an observable
          }
        })
      );
    } else {
      this.loginService.setInfoFlag(false); // Use setInfoFlag to update infoFlag
      this.router.navigate(['/login']);
      return throwError(new HttpErrorResponse({ status: 401 })); // Return an observable error
    }
  }
}
