import { Component, OnInit, } from '@angular/core';
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from 'ng2-charts';
//import { ReportsService } from 'src/app/services/reports.service';
import { ReportsService  } from '../../services/reports.service';
import CountyNames from '../../services/countyNamesService';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { Config } from "../../config";
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var toastr: any









@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss'],

  })

  export class ReportsComponent implements OnInit {



    segment_id: any = '';
    state_name: any = '';
    county_name: any = '';
    status_stage: any = [];
    uniqueStatus :any = [];
    order_type:any = '';
    batch_store_array:any = [];
    fieldlist_data: any = [];
    fieldlist_AllData:any = [];
    fieldlists:any = '';
    BatchTypes:any = [];
    clientBatchType:any = '';
    singleFieldarry:any = [];
    uniqueChars:any = [];
    FieldList:any = [];
    selectedFieldType:any = [];
    single_FieldLists = '';
    batch_flag = false;
    settings1
    settings2
    settings3
    settings4
    settings5
    settings6
    settings7

    segmentTypeOpen
    OrderTypeOpen
    stateOpen
    countyOpen
    StatusTypeOpen
    FieldTypeOpen
    configUrl = new Config().apiUrl




    stateList:any = [];
    SegmentTypes:any = [];
    selectedSegmentType: any = [];
   // SegmentTypes = [{id: 'master',name: 'Title'}]
    selectedReportType:any = [{id: 'master', name: 'Title'}]
    OrderTypes =  [{id: 1,name: 'Single order'},
                   {id: 2,name: 'Batch order'}
                  ]
    //selectedOrderType =[{id: 1,name: 'Single order'}]
   // BatchTypes = [{id: 1,name: 'Bat-C-1100'},{id: 2,name: 'Bat-C-1103'},{id: 3,name: 'Bat-C-1105'},{id: 4,name: 'Bat-C-1108'}]
  // selectedBatchType = [{id: 1,name: 'batch1'}]
    selectedState:any = []
    //CountyList = [{id: 1,name: 'county 1'},{id: 2,name: 'county 2'}]
    selectedCounty:any = []
   StatusList = [{id: 1,name: 'Open'},{id: 2,name: 'Clarification'},{id: 3,name: 'Cancelled'},{id: 4,name: 'Completed'}]
    // selectedStatus:any = []
    // FieldList:any = [{id: 1,name: 'Order no'},{id: 2,name: 'Order ref'},{id: 3,name: 'Product name'},{id: 4,name: 'Address'},{id: 5,name: 'Borrower'},{id: 6,name: 'Lender '},{id: 7,name: 'State'},{id: 8,name: 'County'},{id: 9,name: 'Loan amount'},{id: 10,name: 'Parcel id'}];
   // selectedFieldType: any = [];
    stateRequiredError: boolean;
    countries = CountyNames.names()
    counties: any = []




     constructor(private router: Router,private route: ActivatedRoute,private reportsService: ReportsService, private ngxUILoader: NgxUiLoaderService,
      ) {

        this.stateList = [
          {
              'name': 'Alabama',
              'abbreviation': 'AL'
          },
          {
              'name': 'Alaska',
              'abbreviation': 'AK'
          },
          {
              'name': 'American Samoa',
              'abbreviation': 'AS'
          },
          {
              'name': 'Arizona',
              'abbreviation': 'AZ'
          },
          {
              'name': 'Arkansas',
              'abbreviation': 'AR'
          },
          {
              'name': 'California',
              'abbreviation': 'CA'
          },
          {
              'name': 'Colorado',
              'abbreviation': 'CO'
          },
          {
              'name': 'Connecticut',
              'abbreviation': 'CT'
          },
          {
              'name': 'Delaware',
              'abbreviation': 'DE'
          },
          {
              'name': 'District Of Columbia',
              'abbreviation': 'DC'
          },
          {
              'name': 'Federated States Of Micronesia',
              'abbreviation': 'FM'
          },
          {
              'name': 'Florida',
              'abbreviation': 'FL'
          },
          {
              'name': 'Georgia',
              'abbreviation': 'GA'
          },
          {
              'name': 'Guam',
              'abbreviation': 'GU'
          },
          {
              'name': 'Hawaii',
              'abbreviation': 'HI'
          },
          {
              'name': 'Idaho',
              'abbreviation': 'ID'
          },
          {
              'name': 'Illinois',
              'abbreviation': 'IL'
          },
          {
              'name': 'Indiana',
              'abbreviation': 'IN'
          },
          {
              'name': 'Iowa',
              'abbreviation': 'IA'
          },
          {
              'name': 'Kansas',
              'abbreviation': 'KS'
          },
          {
              'name': 'Kentucky',
              'abbreviation': 'KY'
          },
          {
              'name': 'Louisiana',
              'abbreviation': 'LA'
          },
          {
              'name': 'Maine',
              'abbreviation': 'ME'
          },
          {
              'name': 'Marshall Islands',
              'abbreviation': 'MH'
          },
          {
              'name': 'Maryland',
              'abbreviation': 'MD'
          },
          {
              'name': 'Massachusetts',
              'abbreviation': 'MA'
          },
          {
              'name': 'Michigan',
              'abbreviation': 'MI'
          },
          {
              'name': 'Minnesota',
              'abbreviation': 'MN'
          },
          {
              'name': 'Mississippi',
              'abbreviation': 'MS'
          },
          {
              'name': 'Missouri',
              'abbreviation': 'MO'
          },
          {
              'name': 'Montana',
              'abbreviation': 'MT'
          },
          {
              'name': 'Nebraska',
              'abbreviation': 'NE'
          },
          {
              'name': 'Nevada',
              'abbreviation': 'NV'
          },
          {
              'name': 'New Hampshire',
              'abbreviation': 'NH'
          },
          {
              'name': 'New Jersey',
              'abbreviation': 'NJ'
          },
          {
              'name': 'New Mexico',
              'abbreviation': 'NM'
          },
          {
              'name': 'New York',
              'abbreviation': 'NY'
          },
          {
              'name': 'North Carolina',
              'abbreviation': 'NC'
          },
          {
              'name': 'North Dakota',
              'abbreviation': 'ND'
          },
          {
              'name': 'Northern Mariana Islands',
              'abbreviation': 'MP'
          },
          {
              'name': 'Ohio',
              'abbreviation': 'OH'
          },
          {
              'name': 'Oklahoma',
              'abbreviation': 'OK'
          },
          {
              'name': 'Oregon',
              'abbreviation': 'OR'
          },
          {
              'name': 'Palau',
              'abbreviation': 'PW'
          },
          {
              'name': 'Pennsylvania',
              'abbreviation': 'PA'
          },
          {
              'name': 'Puerto Rico',
              'abbreviation': 'PR'
          },
          {
              'name': 'Rhode Island',
              'abbreviation': 'RI'
          },
          {
              'name': 'South Carolina',
              'abbreviation': 'SC'
          },
          {
              'name': 'South Dakota',
              'abbreviation': 'SD'
          },
          {
              'name': 'Tennessee',
              'abbreviation': 'TN'
          },
          {
              'name': 'Texas',
              'abbreviation': 'TX'
          },
          {
              'name': 'Utah',
              'abbreviation': 'UT'
          },
          {
              'name': 'Vermont',
              'abbreviation': 'VT'
          },
          {
              'name': 'Virgin Islands',
              'abbreviation': 'VI'
          },
          {
              'name': 'Virginia',
              'abbreviation': 'VA'
          },
          {
              'name': 'Washington',
              'abbreviation': 'WA'
          },
          {
              'name': 'West Virginia',
              'abbreviation': 'WV'
          },
          {
              'name': 'Wisconsin',
              'abbreviation': 'WI'
          },
          {
              'name': 'Wyoming',
              'abbreviation': 'WY'
          }
      ];

    }



    ngOnInit() {
       this.FieldList = [{id: 1,name: 'Order no'},{id: 2,name: 'Order ref'},{id: 3,name: 'Product name'},{id: 4,name: 'Address'},{id: 5,name: 'Borrower'},{id: 6,name: 'Lender '},{id: 7,name: 'State'},{id: 8,name: 'County'},{id: 9,name: 'Loan amount'},{id: 10,name: 'Parcel id'}];

     this.selectedFieldType = [
        {id: 1,name: 'Order no'},{id: 2,name: 'Order ref'},{id: 3,name: 'Product name'},{id: 4,name: 'Address'},{id: 5,name: 'Borrower'},{id: 6,name: 'Lender '},{id: 7,name: 'State'},{id: 8,name: 'County'},{id: 9,name: 'Loan amount'},{id: 10,name: 'Parcel id'}
      ];
      console.log("selectedFieldType",this.selectedFieldType);
      for(let item of this.selectedFieldType){
        this.fieldlist_data.push(item.name)
        console.log("fieldlist_data",this.fieldlist_data);     
    }
    this.uniqueChars= this.uniqueChars.concat(this.fieldlist_data);
    console.log("this.uniqueChars",this.uniqueChars);
    



      
  this.getSegments();
  this.settings1 = {
    text: 'Select Segment Type',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    classes: 'myclass custom-class',
    enableSearchFilter: true,
    singleSelection: true,
    primaryKey: "_id",
    labelKey: "name",
    badgeShowLimit: 3
  };
  this.settings2 = {
    text: 'Select Order Type',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    classes: 'myclass custom-class',
    enableSearchFilter: true,
    singleSelection: true,
    primaryKey: "id",
    labelKey: "name",
    badgeShowLimit: 3
  }
  this.settings3 = {
    text: 'Select State',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    classes: 'myclass custom-class',
    enableSearchFilter: true,
    singleSelection: true,
    primaryKey: 'abbreviation',
    labelKey: 'abbreviation',
    badgeShowLimit: 3
  };
  this. settings4 = {
    text: 'Select County',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    classes: 'myclass custom-class',
    enableSearchFilter: true,
    singleSelection: true,
    primaryKey: 'County',
    labelKey: 'County',
    badgeShowLimit: 3
  };
  this.settings5 = {
    text: 'Select Order Type',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    classes: 'myclass custom-class',
    enableSearchFilter: true,
    singleSelection: false,
    primaryKey: "name",
    labelKey: "name",
    badgeShowLimit: 3
  }
  this.settings6 = {
    text: 'Select  Field',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    classes: 'myclass custom-class',
    enableSearchFilter: true,
    singleSelection: false,
    primaryKey: "name",
    labelKey: "name",
    badgeShowLimit: 3
  }

  this.settings7 = {
    text: 'Select Batch Type',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    classes: 'myclass custom-class',
    enableSearchFilter: true,
    singleSelection: true,
    primaryKey: "id",
    labelKey: "name",
    badgeShowLimit: 3
  }
  this.getBatchtypelist();

    }





    getSegments() {
      const type = {
        type:'segment'
      }
      this.reportsService.getMaster(type)
      .subscribe((data: any) => {
      this.SegmentTypes = data;
      console.log(this.SegmentTypes)
      this.selectedSegmentType = [{'_id':data[0]._id, 'name': data[0].name}]
      console.log(this.selectedSegmentType)
      for(let item of this.selectedSegmentType ){
        this.segment_id = item._id
        console.log( this.segment_id )
      }

    })

    }

    getBatchtypelist(){
        this.reportsService.getBatchtype().subscribe((data: any) => {
            console.log(data);
            for(let item of data){
            console.log("item",item);
            this.clientBatchType = item.ref
           this.BatchTypes.push({name:item.ref}) 
           console.log("BatchTypes",this.BatchTypes );
           
            }
        });
       
    }


    segmentTypeSelection(data){
        console.log("seg...",data)
        for(let item of data){
        console.log(item._id)
        this.segment_id =  item._id
        console.log(this.segment_id)
        }
    }
    segmentTypeDeSelection(data){}
    OrderTypeSelection(data){
        console.log(data)
        this.order_type = data;
        console.log(this.order_type)
        for(let item of data){
            this.order_type = item.name
            console.log("final...",this.order_type)
            if(item.name == "Batch order"){
                this.batch_flag = true;
            }
            else{
                this.batch_flag = false;
            }
        }
    }


    OrderTypeDeSelection(data){

    }

    BatchTypeSelection(data){
        console.log(data)
        for(let item of data){
           this.batch_store_array.push(item.name)
           console.log(this.batch_store_array)
        }
    }
    BatchTypeDeSelection(state){}
    stateSelection(state){
        console.log("states",state)
        for(let item of state){
          this.state_name = item.abbreviation
          console.log(this.state_name)
        }
        this.stateRequiredError = false
        this.stateOpen = true
       const x = this.countries.filter( X => state[0].abbreviation.includes(X.State))
        this.counties = x;
         console.log(x)
    }
    stateDeSelection(data){

    }
    countySelection(data){
        console.log(data)
        for(let item of data){
          this.county_name = item.County
          console.log(this.county_name)
        }
    }
    countyDeSelection(data){}
    statusSelection(data){
        console.log(data)
        for(let item of data ){
         this.status_stage.push(item.name) 
         console.log("STATUS....",this.status_stage)
        }

        this.uniqueStatus = this.status_stage.filter((c, index) => {
            return this.status_stage.indexOf(c) === index;
        });

        console.log("UNIQUE Status...",this.uniqueStatus);
        
    }
    statusDeSelection(data){
        console.log("deselet data",data);
        for(let x of this.uniqueStatus){
            if(x == data.name ){
            let index = this.uniqueStatus.findIndex(i => i === data.name)
            this.uniqueStatus.splice(index,1)
                
            }
        }
        console.log("delet status",this.uniqueStatus);      
    }

    statusSelectionAll(data){
        console.log("ALLL select data",data);
        for(let item of data){
            this.status_stage.push(item.name)

        }
        console.log("STATUS....",this.status_stage)
         this.uniqueStatus.push(...this.status_stage);
        console.log("pushed itemsss", this.uniqueStatus);    
    }

    statusDeSelectionAll(data){
        console.log("deselet data",data);
        this.uniqueStatus = [];
        this.status_stage = [];
        
    }
    fieldTypeSelection(data){
        this.uniqueChars = [];
        this.single_FieldLists = '';
        this.fieldlist_data = [];
        this.singleFieldarry = [];
       // console.log("@@@@@@@@@@@",data) 
        for(let item of data){
            this.fieldlist_data.push(item.name)   
        }
   // console.log("arrayyy",this.fieldlist_data);

this.uniqueChars = this.fieldlist_data.filter((c, index) => {
    return this.fieldlist_data.indexOf(c) === index;
}); 
//console.log("individual list",this.uniqueChars); 
for(let d of this.uniqueChars){
    console.log("dddddd",d);
    if(d == 'Product name'){
        this.singleFieldarry.push({product_name: 'Product name'})
    }
    if(d == 'Order no'){
        this.singleFieldarry.push({order_no: 'Order no'}) 
    }
    if(d == 'Order ref'){
        this.singleFieldarry.push({orde_ref: 'Order ref'}) 
    }
    if(d == 'Address'){
        this.singleFieldarry.push({address: 'Address'}) 
    }

    if(d == 'Borrower'){
        this.singleFieldarry.push({borrower: 'Borrower'}) 
    }
    if(d == 'Lender'){
        this.singleFieldarry.push({lender: 'Lender'}) 
    }
    
    if(d == 'State'){
        this.singleFieldarry.push({state: 'State'}) 
    }
    if(d == 'County'){
        this.singleFieldarry.push({county: 'County'}) 
    }
    if(d ==  'Loan amount'){
        this.singleFieldarry.push({loan_amount: 'Loan amount'}) 
    }
    if(d ==  'Parcel id'){
        this.singleFieldarry.push({Parcel_id: 'Parcel id'}) 
    }
    
  //  console.log("single filed",this.singleFieldarry);
this.single_FieldLists = this.singleFieldarry.reduce(function(result, item) {
    var key = Object.keys(item)[0]; //first property: a, b, c
    result[key] = item[key];
    return result;
  }, {});
       //  console.log("object from array",this.single_FieldLists);    
}
    }

    fieldTypeDeSelection(data?:any){
        //console.log("individual list",this.uniqueChars);
        //console.log("deselect",data);
        this.singleFieldarry = [];
        this.single_FieldLists = '';
            for(let x of this.uniqueChars){
                if(x == data.name){
                    let index = this.uniqueChars.findIndex(i => i === data.name)
                        this.uniqueChars.splice(index,1)     
                }
            }
           // console.log("delete dataaa",this.uniqueChars);
            for(let d  of this.uniqueChars ){
                if(d == 'Product name'){
                    this.singleFieldarry.push({product_name: 'Product name'})
                }
                if(d == 'Order no'){
                    this.singleFieldarry.push({order_no: 'Order no'}) 
                }
                if(d == 'Order ref'){
                    this.singleFieldarry.push({orde_ref: 'Order ref'}) 
                }
                if(d == 'Address'){
                    this.singleFieldarry.push({address: 'Address'}) 
                }
            
                if(d == 'Borrower'){
                    this.singleFieldarry.push({borrower: 'Borrower'}) 
                }
                if(d == 'Lender'){
                    this.singleFieldarry.push({lender: 'Lender'}) 
                }
                
                if(d == 'State'){
                    this.singleFieldarry.push({state: 'State'}) 
                }
                if(d == 'County'){
                    this.singleFieldarry.push({county: 'County'}) 
                }
                if(d ==  'Loan amount'){
                    this.singleFieldarry.push({loan_amount: 'Loan amount'}) 
                }
                if(d ==  'Parcel id'){
                    this.singleFieldarry.push({Parcel_id: 'Parcel id'}) 
                }
               // console.log("single filed",this.singleFieldarry);
            }
            this.single_FieldLists = this.singleFieldarry.reduce(function(result, item) {
                var key = Object.keys(item)[0]; //first property: a, b, c
                result[key] = item[key];
                return result;
              }, {});
                  //   console.log("object from array",this.single_FieldLists);    
    }
    
    fieldTypeSelectionAll(data){
    console.log(data);
    for(let y of data){
        this.uniqueChars.push(y.name)
    }
    console.log("select ALL data",this.uniqueChars);
    for(let d of this.uniqueChars){
        console.log("dddddd",d);
        if(d == 'Product name'){
            this.singleFieldarry.push({product_name: 'Product name'})
        }
        if(d == 'Order no'){
            this.singleFieldarry.push({order_no: 'Order no'}) 
        }
        if(d == 'Order ref'){
            this.singleFieldarry.push({orde_ref: 'Order ref'}) 
        }
        if(d == 'Address'){
            this.singleFieldarry.push({address: 'Address'}) 
        }
    
        if(d == 'Borrower'){
            this.singleFieldarry.push({borrower: 'Borrower'}) 
        }
        if(d == 'Lender'){
            this.singleFieldarry.push({lender: 'Lender'}) 
        }
        
        if(d == 'State'){
            this.singleFieldarry.push({state: 'State'}) 
        }
        if(d == 'County'){
            this.singleFieldarry.push({county: 'County'}) 
        }
        if(d ==  'Loan amount'){
            this.singleFieldarry.push({loan_amount: 'Loan amount'}) 
        }
        if(d ==  'Parcel id'){
            this.singleFieldarry.push({Parcel_id: 'Parcel id'}) 
        }
     // console.log("single filed",this.singleFieldarry);    
    }
    }

    fieldTypeDeSelectionAll(data?:any){
        console.log("All delete data",data);
        this.uniqueChars = [];
          this.single_FieldLists = '';
          this.singleFieldarry = [];      
    }

    selctAll(data){
   console.log(data);
    }
  
    isAllDownload(){
        console.log("download")
    //    this.fieldlists = {
    //         client_order_no: "client_order_no",
    //         client_name: "client_name",
    //         product_name: "product_name",
    //         lender_name: "lender_name",
    //         state: this.state_name,
    //         county: this.county_name,
    //         segment: this.segment_id,
    //         status: this.status_stage,
    //         address: "address",
    //         loan_amount: "loan_amount",
    //          buyer_names: "buyer_names",
    //          parcel_ids: "parcel_ids",
    //          order_ref: this.clientBatchType
    //    }

        const datatosend = {
            "segment_id":this.segment_id,
            "order_type_value":this.order_type,
           "batch_order_arr":this.batch_store_array,
           "state":this.state_name,
            "county":this.county_name,
            "status": this.uniqueStatus,
            "field_list":this.uniqueChars,
            "field_lists": this.single_FieldLists

          }
          console.log(datatosend)
          this.reportsService.getClientreport(datatosend)
          .subscribe((data: any) => {
          console.log(data)
          if(data.key != undefined){ 
            let fileName = data.key;
            console.log("this.configUrlthis.configUrl this.configUrlthis.configUrl ",this.configUrl)
            window.location.href = this.configUrl + '/file/read?fileName=' + fileName;
            this.ngxUILoader.stop();
            var self=this; setTimeout(() => {
            this.reportsService.deleteFile(fileName).subscribe((files: any) => {
                // console.log('Delete : ', files)
              })
            }, 300000);

            // window.location.href = 'https://api.closeville.com/api/v1/file/read?fileName=' + fileName;
          }else{
            this.ngxUILoader.stop();
            toastr.error(data)
          }
          })
        //   this.uniqueChars = [];
        //   this.single_FieldLists = '';
        //   this.singleFieldarry = [];
        //   this.uniqueStatus = [];
        //   this.status_stage = [];

    }

   


  }


  //     segment_id:this.segment_id,
  //     order_type_value:this.order_type,
  //    batch_order_arr:this.batch_store_array,
  //    state:this.state_name,
  //     county:this.county_name,
  //     status:this.status_stage,
  //    field_list:this.fieldlist_data,